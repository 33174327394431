import React, { useContext, useState } from "react";
import { Layout, Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import TLanguageSelector from "../LanguageSelector";
import { t } from "../../utilities/Message";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import { MENU_OPTIONS } from "./Constants";

import { ROUTES_GENERAL } from "../../utilities/Constant";
import "./style.scss";
import TButtonLink from "../ButtonLink";
import { main_navigation } from "../../hooks/usePageView";
import { VerifaiContext } from "../../context/verifai/VerifaiProvider";

const { Header } = Layout;

interface HeaderProps {
  className?: string;
}

const THeader = (props: HeaderProps) => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const { setFileOrigin } = useContext(VerifaiContext);

  const history = useHistory();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onNavigate = (menu) => {
    const { route, path, hasScroll = false } = menu;

    const container = document.getElementById(path);
    if (container) {
      window.scrollTo({
        top: container.offsetHeight + 200,
        behavior: "smooth",
      });
    } else {
      history.push({ pathname: route, state: { hasScroll } });
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }
  };

  const _onHandleUpload = () => {
    main_navigation("primary_button", "¡Prueba ahora!");
    setFileOrigin("header");
    history.push(ROUTES_GENERAL.HOME_PAGE);
    const btnUpload = document.getElementById("upload-button");
    if (btnUpload) {
      setTimeout(() => {
        btnUpload.click();
      }, 0);
    }
  };

  return (
    <>
      <Header className="header">
        <div
          className="header__logo"
          onClick={() => {
            main_navigation("image", ROUTES_GENERAL.HOME_PAGE);
            onNavigate({ route: ROUTES_GENERAL.HOME_PAGE });
          }}
        >
          <img
            src={pathServer.PATH_LOGO + "logo_white.svg"}
            className="d-flex pointer"
            alt="logo"
          />
        </div>
        <Menu className="header__menu" mode="horizontal">
          {MENU_OPTIONS.map((menu, index) => (
            <Menu.Item
              key={menu.id}
              onClick={() => {
                main_navigation("link", menu.message);
                onNavigate(menu);
              }}
            >
              {menu.message}
            </Menu.Item>
          ))}
          <Menu.Item>
            <Link
              onClick={() => {
                main_navigation("link", ROUTES_GENERAL.TU_COM);
              }}
              className="d-flex header__tu"
              to={{ pathname: ROUTES_GENERAL.TU_COM }}
              target="_blank"
            >
              Conoce TU
              <ReactSVG
                src={pathServer.PATH_ICONS + "ic_tu.svg"}
                className="icon"
              />{" "}
            </Link>
          </Menu.Item>
        </Menu>
        <div className="header__cta">
          <TButtonLink
            className="header__cta-try"
            onClick={_onHandleUpload}
            name=" ¡Prueba ahora!"
          />
        </div>
      </Header>
    </>
  );
};

THeader.displayName = "THeader";

THeader.defaultProps = {
  className: "",
};

export default THeader;
