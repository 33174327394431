import React from "react";

import { Progress } from "antd";
import { t } from "../../utilities/Message";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import "./style.scss";
import { video_file_analyzed_error } from "../../hooks/usePageView";

// Define your props here
interface ProgressInfoProps {
  className?: string;
  percentage?: number;
  color?: string;
  title?: string;
  content?: string;
  result?: string;
  size?: number;
  withLink?: boolean;
  strokeWidth?: number;
  status?: number;
  message?: string;
}

const TProgressInfo = (props: ProgressInfoProps) => {
  const {
    percentage,
    color,
    className,
    title,
    content,
    result,
    size = 160,
    strokeWidth = 3,
    status,
  } = props;

  React.useEffect(() => {
    if (status === 3) {
      video_file_analyzed_error();
    }
  }, [status]);

  return (
    <div className={`ProgressInfo ${props.className}`}>
      {status === 3 ? (
        <ReactSVG
          src={pathServer.PATH_ICONS + "ic_failed.svg"}
          className={className}
        />
      ) : (
        <Progress
          type="circle"
          percent={Math.floor(percentage)}
          size={size}
          strokeColor={color}
          strokeWidth={strokeWidth}
          className={className}
        />
      )}
      {status === 3 ? (
        <div>
          <div className="ml-1 mt-0">
            Este motor no responde. <br/>
            <span
              className="ml-auto font-bold link-button pointer"
              onClick={window.print}
            >
              {" "}
              Descarga{" "}
            </span>{" "}
            el análisis y envíalo a{" "}
            <a className="underline link-button" href="mailto:soporte@tu.com">soporte@tu.com</a> para poder
            analizar el vídeo.
          </div>

        </div>
      ) : (
        <div className="">
          <p style={{ maxWidth: "200px" }}>
            {title && t(title)}
            <br />
            <strong className="uppercase font-bold">
              {content && t(content)}
            </strong>{" "}
            <br />
            {result && t(result)}
          </p>
        </div>
      )}
    </div>
  );
};

TProgressInfo.displayName = "TProgressInfo";

TProgressInfo.defaultProps = {
  className: "",
};

export default TProgressInfo;
