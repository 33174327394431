import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { pathServer } from "../../utilities/Function";
import { withLanding } from "../../hocs/withLanding";
import "./styles.scss";
import { ROUTES_GENERAL } from "../../utilities/Constant";

const ErrorPage = (props) => {
  const isExpired = props?.location?.state?.isExpired;
  const urlImg = isExpired ? "error/error500.png" : "error/error400.png";
  const withImg = isExpired ? 350 : 700;
  const history = useHistory();
  return (
    <div className={"error_boundary "}>
      <img
        width={withImg}
        src={pathServer.PATH_IMG + urlImg}
        alt="Error Page"
      />
      {isExpired ? (
        <h2>
          ¡Ups! <br /> Algo no funcionó como esperábamos
        </h2>
      ) : (
        <h2>¡Ups! Error 404</h2>
      )}
      {isExpired ? (
        <h3>
          No hemos podido procesar el archivo que has subido debido a
          dificultades técnicas. <br />
          Por favor, inténtalo de nuevo para que podamos procesarlo
          correctamente.
        </h3>
      ) : (
        <h3>
          Parece que te has perdido o que no hemos localizado lo que estabas
          buscando. <br /> Por favor, revisa la dirección de la página por si
          existe alguna errata.
        </h3>
      )}
      <Button
        type="primary"
        size="large"
        className="mt-1"
        onClick={() => history.push(ROUTES_GENERAL.HOME_PAGE)}
      >
        Volver al inicio
      </Button>
    </div>
  );
};

export default withLanding(ErrorPage, true);
