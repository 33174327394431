import React, { useContext, useEffect, useState } from "react";
import { withLanding } from "../../hocs/withLanding";
import TResult from "../../components/Result";
import { useHistory, useParams } from "react-router-dom";

import { VerifaiContext } from "../../context/verifai/VerifaiProvider";
import { message } from "antd";

import { generateIframe, closeIframe } from "../../utilities/Captcha";

import "./styles.scss";
import {
  do_payment,
  page_view,
  user_interaction,
  cancel_payment,
} from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { fileSizeStr } from "../../../infraestructure/utils/helpers";
import { TIME_OUT_API_CALL } from "../../configuration/config";
import { container } from "../../../inject_container/injection";
import {
  PAYMENT_PORT,
  PaymentPort,
} from "../../../domain/port/inbound/PaymentPort";

const ResultPage = () => {
  const { id, accessToken } = useParams<{ id: string; accessToken: string }>();
  const [messageApi, contextHolder] = message.useMessage();

  const {
    loading,
    data,
    error,
    onCheckResult,
    retry,

    waitingSyncPayment,
  } = useContext(VerifaiContext);
  const [isPaymentProgress, setIsPaymentProgress] = useState(false);
  const history = useHistory();
  const { locale } = useContext(LanguageContext);
  const payloadRequest = container.get<PaymentPort>(PAYMENT_PORT);

  const checkResult = async () => {
    if (id && accessToken) {
      await onCheckResult(id, accessToken);
    }
  };

  const doPayment = async () => {
    try {
      setIsPaymentProgress(true);
      const response = await payloadRequest.doPayment(id);

      const iframe = response?.url;
      user_interaction("video_analysis");
      //@ts-ignore
      do_payment(data.payment_details.price, fileSizeStr(data?.filesize));
      if (iframe) {
        generateIframe(iframe, () => {
          cancel_payment();
          history.goBack();
        });
      } else {
        //Handle error
        messageApi.destroy();
        messageApi.error(response.detail);
      }
    } catch (e) {
      messageApi.destroy();
      messageApi.error("Error al procesar el pago");
      console.error("Payment error: ", e);
      setIsPaymentProgress(false);
    }
  };

  useEffect(() => {
    checkResult();
    page_view({ page_type: EVENTS_TGM.home, locale });
  }, []);

  useEffect(() => {
    if (retry > 0) {
      setTimeout(() => {
        checkResult();
      }, TIME_OUT_API_CALL);
    }
  }, [retry]);

  useEffect(() => {
    if (error?.code === 500) {
      history.push({
        pathname: ROUTES_GENERAL.ERROR,
        state: { isExpired: true },
      });
    }
  }, [error]);

  useEffect(() => {
    const eventHandler = (event) => {
      const {
        data: { message },
      } = event;
      if (message?.type === "end") {
        closeIframe();
        if (message.data.result === "error") {
          history.push({
            pathname: ROUTES_GENERAL.HOME_PAGE,
            state: {
              errorPayment:
                "El pago no se ha procesado. Vuelve a intentarlo subiendo el video de nuevo",
            },
          });
          window.history.replaceState({}, "");
        }
      }
    };

    window.addEventListener("message", eventHandler);
    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, []);

  return (
    <div className="result-page ">
      {contextHolder}
      {!error && (
        <TResult
          id={id}
          className="max-width"
          loading={loading}
          data={data}
          doPayment={doPayment}
          isPaymentProgress={isPaymentProgress}
          waitingSyncPayment={waitingSyncPayment}
        />
      )}
    </div>
  );
};

export default withLanding(ResultPage);
