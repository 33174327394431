// constantes.js

export const TABS_INFO = [
  {
    key: "1",
    tab: "Cómo se usa",
    description: () => {
      return (
        <div>
          <h2>Contenido sin coste</h2>
          <p>
            Analizar contenido de imagen o audio con VerifAI no implica coste
            asociado, cumpliendo con los requisitos técnicos explicados en
            apartado "Tipología de archivos".{" "}
          </p>

          <h2 className="mt-3">Contenido premium con coste asociado</h2>
          <p>
            El análisis de vídeo es una funcionalidad premium que tiene un coste
            asociado de 0,03€/MB (impuestos incluidos). Cuando subas el vídeo se
            calculará el coste del análisis y se te mostrará en pantalla. Si
            estás de acuerdo haz clic en "Pagar" para proceder al pago. <br />{" "}
            Se te redirigirá a nuestra pasarela de pago. Si la transacción se
            realiza correctamente, se procederá al análisis y te mostraremos el
            resultado en pantalla. Existe la posibilidad de descargar el
            resultado haciendo click en "Descargar análisis".
          </p>
        </div>
      );
    },
  },
  {
    key: "2",
    tab: "Tipología de archivos",
    description: () => {
      return (
        <div>
          <h2>Video</h2>
          <p>
            {" "}
            El análisis de un archivo o url de vídeo es una funcionalidad
            premium e implica un coste asociado.
          </p>
          <ul>
            <li>Tamaño máximo 30MB.</li>
            <li>Resolución HD (1280x720 px) en formato .mov y .mp4.</li>
            <li>Resolución Full HD (1920x1080 px) en formato .mp4.</li>
            <li>
              {" "}
              Además, para que nuestros motores puedan analizar el contenido
              correctamente, el vídeo debe mostrar una única cara.
            </li>
          </ul>

          <h2 className="mt-2">Imagen</h2>
          <p>
            {" "}
            El archivo de imagen debe cumplir con los siguientes requisitos
            técnicos:
          </p>
          <ul>
            <li>Tamaño mínimo: 100 x 100 px</li>
            <li>Tamaño máximo 2400 x 2400 px</li>
            <li>Formato .jpg, .jpeg, .png o .heic</li>
          </ul>

          <h2 className="mt-2">Audio</h2>
          <p>
            {" "}
            El archivo de audio debe cumplir con los siguientes requisitos
            técnicos:
          </p>
          <ul>
            <li>
              Duración recomendada entre 10 y 60 segundos, con un máximo de 5
              minutos.
            </li>
            <li>Formato mp3 o wav</li>
          </ul>
        </div>
      );
    },
  },
  {
    key: "3",
    tab: "Resultados del análisis",
    description: () => {
      return (
        <div>
          <h2>Cómo interpretar el resultado</h2>
          <p>
            El porcentaje que se muestra indica la probabilidad de que la imagen
            haya sido generada o manipulada por IA. Se han agrupado los
            resultados en tres categorías:
          </p>
          <ul style={{ listStyle: "none" }}>
            <li>
              <strong style={{ color: "#4343FF" }}>Poco probable: </strong>{" "}
              creemos que la imagen es real y no ha sido manipulada
            </li>
            <li>
              <strong style={{ color: "#4343FF" }}> No concluyente: </strong>{" "}
              hemos encontrado algún indicio de manipulación, pero no podemos
              obtener una conclusión definitiva.
            </li>
            <li>
              <strong style={{ color: "#4343FF" }}> Probablemente IA:</strong>{" "}
              el análisis arroja indicios claros de que la imagen no es real.
            </li>
          </ul>
          <h2 className="mt-2">Determinación del resultado</h2>
          <p>
            Estamos mejorando permanentemente nuestros sistemas para realizar
            análisis más precisos, pero las herramientas para generar contenidos
            sintéticos también mejoran continuamente. Por eso no podemos
            garantizar que el resultado obtenido sea correcto.
          </p>{" "}
          <p className="mt-1">
            {" "}
            Recomendamos que se tome como el punto de partida para una
            investigación más detallada. Si detectas que nos hemos equivocado y
            quieres ayudarnos a mejorar VerifAI, puedes escribirnos a
            verifai@tu.com
          </p>
        </div>
      );
    },
  },
  {
    key: "4",
    tab: "Tecnología",
    description: () => {
      return (
        <div>
          <h2>Cómo detectamos deep fakes en vídeos</h2>
          <p></p>
          Nos especializamos en la detección de anomalías mediante la aplicación
          de técnicas de aprendizaje profundo, más concretamente, utilizamos un
          Vision Transformer (VIT). <br /> Nuestro enfoque implica entrenar un
          modelo de codificador Transformer diseñado para identificar deepfakes,
          y lo implementamos utilizando el marco FastAI. Para cada vídeo subido,
          nuestro proceso comienza extrayendo fotogramas de los 10 segundos
          iniciales. Posteriormente, realizamos la detección de rostros en cada
          cuadro. En aquellos en los que detectamos rostros, aplicamos nuestro
          modelo VIT entrenado.
          <br /> Finalmente, los resultados de cada fotograma se agregan para
          calcular una estimación de la probabilidad de que el rostro humano que
          aparece en el vídeo haya sido generado artificialmente.
          <h2 className="mt-3">Cuáles son nuestros modelos propios</h2>
          <p>
            Como parte de nuestra actividad de innovación hemos desarrollado
            nuestros propios modelos para analizar vídeo. Uno de los indicadores
            para detectar que una persona ha sido generada por IA son los
            movimientos de la cabeza, muchas veces poco naturales, repetitivos
            o, en ocasiones, inexistentes. Nuestro modelo patentado Headpose
            analiza esos movimientos par detectar posibles fraudes. <br /> Uno
            de los detalles más complejos de generar mediante IA es el
            movimiento natural de los ojos. Blink es nuestro modelo para
            analizar esos movimientos y detectar si son reales o no.
          </p>
          <h2 className="mt-3">Cómo detectamos imágenes manipuladas</h2>
          <p>
            Para la versión Beta hemos integrado un modelo público disponible en
            Hugging Face. Está basado en Swin Transformer For Image
            Classification, que es una adaptación del modelo original de
            Transformer diseñado para problemas de lenguaje, pero adaptado para
            el reconocimiento de imágenes. La diferencia fundamental radica en
            la representación jerárquica de los píxeles de las imágenes para
            aumentar la eficiencia del modelo original.
          </p>
        </div>
      );
    },
  },
];
