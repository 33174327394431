import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";

import "./styles.scss";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { page_view } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";

const ConditionsPageV2 = () => {
  const { locale } = useContext(LanguageContext);

  const LINK_TELEFONICA = (
    <Link
      style={{ color: "#4343ff" }}
      to={{ pathname: "https://www.tu.com/" }}
      target="_blank"
    >
      {" "}
      www.tu.com
    </Link>
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    page_view({ page_type: EVENTS_TGM.conditions, locale });
  }, []);

  return (
    <div className="conditions-page how-it-works ">
      <h1 className="text-center font-semibold">
        Condiciones Particulares de la Beta del Servicio TU VerifAI
      </h1>
      <div className="max-width  mb-3 conditions-page-body">
        <p>
          Estas Condiciones Particulares son complementares a los Términos y
          Condiciones Generales de Uso de los Servicios Tu.com disponibles en{" "}
          {LINK_TELEFONICA} y son específicas de la beta del{" "}
          <b>Servicio TU VerifAI</b>. (en adelante, el "Servicio"), provisto por
          <b> TELEFÓNICA INNOVACIÓN DIGITAL, S.L.U.</b>, empresa con NIF nº
          B83188953, domicilio social en Ronda de la Comunicación, s/n, Edificio
          Central, 28050, Madrid, e inscrita en el Registro Mercantil de Madrid
          al tomo 42.773, folio 213, inscripción 79, hoja M-296237 (en adelante,
          <b>"Telefónica"</b>).
        </p>
        <p className="mt-1 mb-1 font-bold">1. ACEPTACIÓN</p>

        <p>
          La aceptación, sin reservas, de estas Condiciones Particulares es
          indispensable para el uso en modalidad de prueba (“beta”) del Servicio
          por cualquier persona interesada.
          <b>
            {" "}
            En el caso de que el Usuario no esté conforme con estas Condiciones
            Particulares no debe utilizar el servicio.
          </b>
        </p>

        <p className="mt-1 mb-1 font-bold">
          2. DESCRIPCI&Oacute;N DEL SERVICIO
        </p>
        <p>
          TU VerifAI es una solución desarrollada por Telefónica, sobre motores
          propios y de terceros, que tiene como objetivo detectar si un
          determinado contenido proporcionado por el Usuario fue generado o
          modificado de alguna forma por sistemas de Inteligencia Artificial
          (“IA”). De este modo, TU VerifAI permite al Usuario conocer la
          probabilidad (en un porcentaje estimado) de que el contenido
          consultado se haya generado o modificado mediante IA. El resultado se
          ofrece a título estimativo. Telefónica NO ofrece ninguna garantía y no
          proporciona ninguna conformidad sobre la exactitud o fiabilidad del
          resultado. <br /> Para utilizar el Servicio, el Usuario podrá
          proporcionar el contenido en formato de imagen, video o audio (en
          adelante, el “Contenido”), sin perjuicio de que Telefónica pueda
          modificar o habilitar nuevos formatos de consulta de forma
          discrecional.
          <br />
          TU VerifAI está desarrollado sobre licencias open source de terceros y
          bases de datos públicas, entrenadas por TELEFÓNICA entre las que se
          encuentran:
        </p>

        <ul>
          {" "}
          <li>Reddit_eli5-ELI5 under BSD-License</li>
          <li>Open_qa-WikiQA under PWC Custom license</li>
          <li>Wiki_csai-Wikipedia under CC-BY-SA license</li>
          <li>Medicine of Medical Dialog</li>
          <li>Finance de FiQA</li>
          <li>AI-image-detector of umm-maybe under CC-BY-4.0 license</li>
        </ul>

        <p className="mt-1 mb-1 font-bold">3. Modalidades del Servicio</p>
        <p>
          VerifAI permite al Usuario consultar el Contenido a través de 3
          formatos distintos: imagen, video o audio. Actualmente las modalidades
          de <strong>imagen</strong> o <strong>audio</strong> se prestan de
          forma <strong>gratuita</strong> mediante la aceptación de estas
          Condiciones y demás documentación aplicable al Servicio, conforme se
          informa al Usuario en la consulta. <br /> La modalidad de video es de
          pago y por lo tanto tiene un coste asociado para el Usuario (además de
          la aceptación de estas Condiciones y demás documentación aplicable al
          Servicio). Este coste se calcula de acuerdo con el tamaño del video
          que se pretende consultar. Te recomendamos revisar las limitaciones de
          Contenido existentes para esta modalidad, informadas en el apartado
          “Me interesa”. Para más información puedes consultar la sesión de
          ayuda de TU VerifAI. El importe de la Consulta indicado en la página
          web incluye el impuesto sobre el valor añadido aplicable en cada
          momento. El Usuario deberá facilitar su país / lugar de residencia
          para su adecuada aplicación. Los impuestos asociados al precio del
          Servicio serán abonados por quien corresponda según la Ley. En caso de
          que resulte de aplicación un convenio para evitar la doble imposición,
          ambas Partes aplicarán dicho convenio siempre que se aporte en tiempo
          y forma cuanta documentación sea necesaria para poder aplicarlo,
          especialmente un certificado de residencia fiscal.
        </p>

        <p className="mt-1 mb-1 font-bold">
          4. NUESTRO COMPROMISO CON TU VERIFAI
        </p>
        <p>
          A trav&eacute;s de TU VerifAI TELEF&Oacute;NICA tiene como
          intenci&oacute;n colaborar con la sociedad proporcionando herramientas
          que permitan a las personas interesadas conocer una estimaci&oacute;n
          sobre si determinada informaci&oacute;n o contenido es originalmente
          desarrollado por una persona o si es resultado del uso de Inteligencia
          Artificial, y as&iacute; ayudar a formar una opini&oacute;n m&aacute;s
          realista acerca de dicho contenido e informaci&oacute;n relacionada
          con &eacute;ste.
        </p>
        <p>
          En el marco de este prop&oacute;sito, TELEF&Oacute;NICA asume frente a
          los Usuarios de VerifAI ciertos compromisos relevantes:
        </p>
        <div className="ml-1">
          <p>
            i. Minimizamos al máximo el tratamiento de datos personales de los
            Usuarios, no es necesario que el usuario se registre para acceder al
            Servicio. Te informamos que en el caso de los servicios de pago, el
            Usuario deberá proporcionar los datos necesarios para su correcta
            facturación.
          </p>
          <p>
            ii. No almacenamos el Contenido. Solo almacenamos una firma digital
            o hash del Contenido asociado a la estimación que se ha generado,
            ello con el fin de facilitar el acceso a la estimación calculada
            para dicho Contenido por aquellos otros Usuarios que usen TU VerifAI
            para un Contenido ya consultado antes. Un hash es una cadena de
            texto codificada formada por número y letras, irrepetible, que
            representan inequívocamente el Contenido. El hash no es reversible,
            es decir, el Contenido no se puede obtener a través del hash y, por
            eso, no almacenamos el Contenido consultado.
          </p>
          <p>
            iii. Recordamos que el resultado no es conclusivo, exacto ni fiable,
            es un punto de partida a través del cual el Usuario puede hacer, por
            su cuenta y riesgo, un análisis más profundo acerca del origen del
            Contenido.
          </p>
          <p>
            iv. TELEF&Oacute;NICA proporcionar&aacute; el Servicio conforme a
            los t&eacute;rminos dispuestos en los{" "}
            <b>Términos de Uso de los Servicios Tu.com</b>, disponibles en{" "}
            {LINK_TELEFONICA} .
          </p>
          <p>
            v. Mantendremos un canal de contacto a través del cual el USUARIO podrá
            contactarnos para consultas relacionadas con el Servicio y/o acerca
            del resultado de la Consulta, en caso de que no esté de acuerdo con
            el mismo y/o tenga dudas al respecto. Telefónica se reserva el
            derecho (pero no la obligación) a habilitar mecanismos para que bien
            Telefónica, terceros, y/o el propio Usuario puedan (1) solicitar la
            revisión del resultado para un Contenido concreto, así como (2)
            listar firmas digitales o hashes de Contenidos sobre los que el
            Servicio no funcionará y sobre los que no se devolverá resultado o
            estimación alguna. Estos canales de contacto, y los mecanismos
            indicados, podrán estar sujetos a condiciones particulares que
            deberán de ser leídas y aceptadas antes de su utilización.
          </p>
        </div>

        <p className="mt-1 mb-1 font-bold">
          5. TUS COMPROMISOS Y OBLIGACIONES COMO USUARIO DE TU VERIFAI
        </p>
        <p>El USUARIO se compromete a:</p>
        <div className="ml-1">
          <p>
            a) Hacer un uso del Servicio de una forma correcta, diligente y
            acorde con la ley, con los{" "}
            <b>
              {" "}
              Términos de Uso de los servicios Tu.com disponibles en{" "}
              {LINK_TELEFONICA}{" "}
            </b>{" "}
            y con las presentes Condiciones Particulares de TU VerifAI y, en
            particular, a abstenerse de utilizarlo con fines distintos de los
            expresamente permitidos en estas Condiciones Generales, en otras
            condiciones aplicables o del aviso legal de la web de TU VerifAI.
          </p>
          <p>
            b) Hacerse &uacute;nico responsable del Contenido que consulta a
            trav&eacute;s de TU VerifAI y de la divulgaci&oacute;n que haga del
            resultado consultado, de conformidad con lo establecido en estas
            Condiciones Generales
          </p>
          <p>
            c) Poner en conocimiento de TELEF&Oacute;NICA aquellas incidencias
            que le impidan o dificulten poder acceder al Servicio. As&iacute;
            como, cualquier incidencia, que el Usuario quiera comunicar a
            TELEF&Oacute;NICA debido al resultado obtenido y/o acerca de un
            Contenido que resulte ser de titularidad del Usuario y que este
            pretenda limitar su consulta amparado en un derecho leg&iacute;timo.
          </p>
          <p>
            d) Cumplir con todo lo establecido en estas Condiciones Particulares
            y en la legislaci&oacute;n aplicable.
          </p>
        </div>
        <p className="mt-1 mb-1 font-bold">6. CONTACTO Y RECLAMACIONES</p>
        <p>
          El USUARIO podr&aacute; comunicarse con TELEF&Oacute;NICA a
          trav&eacute;s de la siguiente direcci&oacute;n de correo
          electr&oacute;nico: verifai@tu.com
        </p>
        <p className="mt-0">
          El Usuario podrá enviar cualquier solicitud o reclamación relacionada
          con el Servicio y/o con el resultado de la Consulta, como, por
          ejemplo, en el caso de que entienda que la estimativa proporcionada
          por TU VerifAI como resultado de la Consulta no es adecuada y/o en el
          caso de que quiera comunicar cualquier derecho propio y/ de tercero
          acerca del Contenido consultado, así como solicitar limitaciones al
          uso de este Contenido (propio) por parte de TU VerifAI.
        </p>
        <p>
          A estos efectos, el soporte y atención al cliente de los Usuarios se
          realizará online a través de dicha dirección y será atendido a la
          mayor brevedad, en horario laboral de lunes a viernes, siempre que no
          sea festivo en España.
        </p>
      </div>
    </div>
  );
};

export default withLanding(ConditionsPageV2);
