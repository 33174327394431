export const CAROUSEL_ITEMS = [
  {
    id: 1,
    title:
      "Cómo funciona VerifAI para detectar contenido generado o manipulado por IA",
    description:
      "VerifAI utiliza motores especializados que se adaptan al tipo de contenido analizado, ya sea imagen, audio o vídeo. Para garantizar la máxima precisión emplea tanto modelos propios, como modelos de proveedores externos, asegurando así una detección robusta y fiable en diferentes formatos",
    description2:
      "Además, VerifAI complementa su tecnología con modelos de terceros, asegurando así una detección robusta y fiable de deep fakes en diversos formatos",
    img: "images/carousel/how_works_1.png",
  },
  {
    id: 2,
    title: "Privacidad de los datos y ética de la IA en VerifAI",
    description:
      "En VerifAI, la privacidad de los datos y la ética en el uso de la inteligencia artificial son prioridades fundamentales. Todos los datos analizados son tratados con el más alto nivel de confidencialidad y seguridad, cumpliendo con las normativas internacionales de protección de datos.",
    description2:
      "Además, VerifAI se compromete a utilizar la IA de manera ética, asegurando que sus modelos y algoritmos sean transparentes, justos y libres de sesgos garantizando que el uso de la IA beneficie a la sociedad sin comprometer la privacidad ni los derechos de los individuos.",
    img: "images/carousel/how_works_2.png",
  },
  {
    id: 3,
    title: "VerifAI PRO Beta",
    description:
      "La versión Beta de VerifAI PRO es una solución avanzada diseñada específicamente para empresas, que integra los mejores motores del mercado según el caso de uso, garantizando resultados más precisos y detallados. Esta solución empresarial permite a las compañías acceder a tecnologías de vanguardia para la detección de deep fakes y otros análisis avanzados, adaptándose a sus necesidades específicas",
    description2:
      "Las empresas interesadas en aprovechar las capacidades de VerifAI Beta PRO pueden ponerse en contacto con nosotros a través del formulario disponible en nuestra web",
    img: "images/carousel/how_works_3.png",
  },
];
