import { container } from "../../inject_container/injection";
import { GTMPort, GTM_PORT } from "../../domain/port/inbound/GTMPort";
import { normalizeComponentCopy } from "../utilities/Function";

const BUSINESS_MODEL_DEFAULT = "B2C";

const getBasePath = () => {
  const { pathname, search, hash } = window.location;
  const path = `${window.location.origin}${pathname}${search}${hash}`;
  const previousPagePath = localStorage.getItem("previousPagePath") || "/";

  localStorage.setItem("previousPagePath", path);
  return {
    page_name: path,
    previous_page_path: previousPagePath,
    clean_url: `${window.location.origin}${pathname}`,
    business_model: BUSINESS_MODEL_DEFAULT,
  };
};

/**
 * Custom hook to detect page visits and navigations
 * and send a PageView analytic event when the URL changes.
 */
export const page_view = (props) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  const { page_type, locale = "es" } = props;

  gtm.emitEvent("PageDataLayer", {
    event: "page_view",
    page_type,
    locale: locale === "es" ? "ES" : "EN",
    ...getBasePath(),
  });
};

export const user_interaction_analysis_cancel = (typeFile) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  gtm.emitEvent("PageDataLayer", {
    event: "user_interaction",
    page_type: "home",
    locale: "ES",
    component_type: "primary_button",
    component_copy: normalizeComponentCopy("Cancelar"),
    module_name: typeFile === "image" ? "image_analysis" : "audio_analysis",
    content_type: typeFile === "image" ? "image" : "audio",
    ...getBasePath(),
  });
};

export const user_interaction_other = (typeFile, rest = {}) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  gtm.emitEvent("PageDataLayer", {
    event: "user_interaction",
    page_type: "home",
    locale: "ES",
    component_type: "link",
    component_copy: normalizeComponentCopy("Analizar otro archivo"),
    module_name: typeFile === "image" ? "image_analysis" : "audio_analysis",
    content_type: typeFile === "image" ? "image" : "audio",
    ...getBasePath(),
    ...rest,
  });
};

export const file_analyzed = (module_name, typeFile, ai_probability = "") => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  gtm.emitEvent("PageDataLayer", {
    event: "file_analyzed",
    page_type: "home",
    locale: "ES",
    module_name: module_name,
    content_type: typeFile === "image" ? "image" : "audio",
    ai_probability: ai_probability,
    ...getBasePath(),
  });
};

export const file_analyzed_payment = (module_name, size) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  gtm.emitEvent("PageDataLayer", {
    event: "file_analyzed",
    page_type: "home",
    locale: "ES",
    module_name: module_name,
    video_analysis_size: size,
    ai_probability: "",
    content_type: "video",
    ...getBasePath(),
  });
};

export const view_payment_summary = (price: string, size: string) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  gtm.emitEvent("PageDataLayer", {
    event: "view_payment_summary",
    page_type: "home",
    component_type: "primary_button",
    locale: "ES",
    video_analysis_price: price,
    video_analysis_size: size,
    module_name: "video_analysis",
    content_type: "video",
    ...getBasePath(),
  });
};

export const download_analysis = (price: string, size: string) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  gtm.emitEvent("PageDataLayer", {
    event: "user_interaction",
    page_type: "home",
    component_type: "link",
    component_copy: normalizeComponentCopy("Descargar analisis"),
    locale: "ES",
    video_analysis_price: price,
    video_analysis_size: size,
    module_name: "video_analysis",
    content_type: "video",
    ...getBasePath(),
  });
};
