/* eslint-disable import/no-anonymous-default-export */
import ErrorPage from "../../pages/ErrorPage";
import {
  HomePage,
  HowItWorksPage,
  HelpPage,
  LegalPage,
  ConditionPage,
  ConditionsPageV2,
  PrivacyPage,
} from "../../pages/index";
import ResultPage from "../../pages/result/ResultPage";
import { ROUTES_GENERAL } from "../../utilities/Constant";

export default [
  {
    path: ROUTES_GENERAL.HOME_PAGE,
    component: HomePage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.HOW_WORKS,
    component: HowItWorksPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.HELP,
    component: HelpPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.LEGAL,
    component: LegalPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.CONDITIONS,
    component: ConditionPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.CONDITIONS_V2,
    component: ConditionsPageV2,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.CONDITIONS_V2_EN,
    component: ConditionsPageV2,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.PRIVACY,
    component: PrivacyPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.RESULT + "/:id/:accessToken",
    component: ResultPage,
  },
  {
    path: "*",
    component: ErrorPage,
  },
  {
    path: ROUTES_GENERAL.ERROR,
    component: ErrorPage,
  },
];
