import moment from "moment-timezone";
import "moment/locale/es";
import { formatDateApi, formatDatePicker } from "./Constant";

export const STATUS_TAKS = {
  0: "queue",
  1: "running",
  2: "finished",
  3: "failed",
};

export const STATUS_TAKS_NUMBER = {
  queue: 0,
  running: 1,
  finished: 2,
  failed: 3,
};

// .tz('America/Lima', true).locale('es')
export default {
  trimCharacer: (str: string, character?: string): string => {
    str = str.replace(/\s/g, character ? character : "");
    let res: string = str;
    return res;
  },
  fixeToTwiDecimals: (value: number): string => {
    return (+value).toFixed(2);
  },
  formatToMoney: (value: number): string => {
    return `S/ ${(+value).toFixed(2)}`;
  },
};

export function hideText(text) {
  // Convertir el número a cadena
  var numberString = String(text);

  // Obtener las primeras 4 letras
  var firstDigits = numberString.substring(0, 4);

  // Obtener las últimas 3 letras
  var lastDigits = numberString.substring(numberString.length - 12);

  // Crear el número oculto
  var hiddenNumber = "xxxx" + lastDigits + "xxx";

  return hiddenNumber;
}

export const isTaskLoading = (status) =>
  status === STATUS_TAKS_NUMBER.queue || status === STATUS_TAKS_NUMBER.running;

export function formatToMoney(value) {
  if (value === undefined) {
    return `S/0.00`;
  }

  if (isNaN(value)) {
    return `S/${+value}`;
  }
  return `S/ ${(+value).toFixed(2)}`;
}

export function maxLengthCheck(evt) {
  const theEvent = evt || window.event;
  let key;
  // Handle paste
  if (theEvent.type === "paste") {
    key = evt.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]|\./;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}

export const formatSecondsToMMSS = (seconds) => {
  const duration = moment.duration(seconds, "seconds");
  const formattedTime = moment
    .utc(duration.asMilliseconds())
    .format("mm:ss.SS");
  return formattedTime;
};

export function removeDays(date, days) {
  date = new Date(date);
  date.setDate(date.getDate() - days);
  return date;
}

export function formatDate(date) {
  return moment(date).format(formatDateApi);
}

export function formatDatePrintRemmisse(dateFrom, dateTo) {
  const dateWeek = moment(dateFrom).format("dddd");
  const dateFormattedStart = moment(dateFrom).format(formatDatePicker);
  const dateFormattedEnd = moment(dateTo).format("DD MMM, YYYY");
  return dateWeek + " " + dateFormattedStart + " - " + dateFormattedEnd;
}

export const pathServer = {
  PATH_ICONS: `/icons/`,
  PATH_LOGO: `/logo/`,
  PATH_IMG: `/images/`,
  PATH_FAVICON: `/favicon/`,
};

export const onScrollHeader = () => {
  const section1 = document.getElementById("container-1");
  const section2 = document.getElementById("container-2");

  const section1Top = section1.offsetTop;
  const section2Top = section2.offsetTop;

  const itemsToSelect = document.getElementsByClassName(
    "ant-menu-overflow-item"
  );

  if (itemsToSelect.length > 0) {
    if (window.scrollY >= section2Top - section2.clientHeight / 3) {
      itemsToSelect[1].classList.add("ant-menu-item-selected");
      itemsToSelect[0].classList.remove("ant-menu-item-selected");
    } else if (window.scrollY >= section1Top - section1.clientHeight / 3) {
      itemsToSelect[0].classList.add("ant-menu-item-selected");
      itemsToSelect[1].classList.remove("ant-menu-item-selected");
    }
  }
};

export const onLoadThumbnail = (
  urlImage,
  thumbnail_width,
  thumbnail_height,
  setImages,
  lengthImages
) => {
  const loadedImages = [];

  const originalImage = new Image();
  originalImage.crossOrigin = "Anonymous";
  originalImage.src = urlImage;
  originalImage.onload = function () {
    for (let i = 0; i < lengthImages; i++) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const sectionWidth = thumbnail_width;
      const sectionHeight = thumbnail_height;

      ctx.clearRect(0, 0, sectionWidth, sectionHeight);
      ctx.drawImage(
        originalImage,
        i * sectionWidth,
        0,
        sectionWidth,
        sectionHeight,
        0,
        0,
        sectionWidth,
        sectionHeight
      );
      loadedImages.push(canvas.toDataURL());
    }
    setImages(loadedImages);
  };

  return loadedImages;
};

export const generateIframe = (iframeUrl) => {
  const overlay = document.createElement("div");
  overlay.className = "overlay";
  document.body.appendChild(overlay);

  const container = document.createElement("div");
  container.className = "iframe-container";
  document.body.appendChild(container);

  const iframe = document.createElement("iframe");
  iframe.src = iframeUrl;
  iframe.width = "100%";
  iframe.frameBorder = "0";
  iframe.allow = "payment";
  iframe.className = "payment-iframe";

  const existingIframe = document.getElementById("paymentIframe");
  if (existingIframe) {
    existingIframe.remove();
  }

  iframe.id = "paymentIframe";
  container.appendChild(iframe);

  document.body.classList.add("no-scroll");
};

function removeElementsByClass(className) {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }
}
export const closeIframe = () => {
  const existingIframe = document.getElementById("paymentIframe");
  if (existingIframe) {
    existingIframe.remove();
    const overlay = document.querySelector(".overlay");
    if (overlay) {
      overlay.remove();
    }

    document.body.classList.remove("no-scroll");

    removeElementsByClass("iframe-container");
  }
};

export const normalizeComponentCopy = (text) =>
  text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "_")
    .replace(/[^\w_]/g, "");
